import * as React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../texts/Headline";
import Description from "../../texts/Description";
import RichDescription from "../../texts/RichDescription";
import Button from "../../buttons/Button";
import Content from "../../layout/Content/Content";
import TemplateThumbnailsPagination from "./TemplateThumbnailsPagination";

const HeadlineExtended = Styled(Headline)`
  font-size: 3.5rem;

  ${Media.lessThan("medium")`
    font-size: 2.5rem;
  `}
`;

const DescriptionExtended = Styled(Description)`
  margin-bottom: 5rem;
`;

const Columns = Styled.div`
    display: flex;
    margin-bottom: 10rem;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    
    ${Media.greaterThan("900px")`
        flex-direction: row;
        margin-bottom: 12rem;
    `}
`;

const ColumnText = Styled.div`
    width: 100%;
`;

const Examples = Styled.div`
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  flex-direction: column;
  flex-wrap: wrap;

  ${Media.greaterThan("medium")`
    width: 60rem;
    flex-direction: row;
  `}

  ${Media.greaterThan("large")`
    width: 100%;
  `}
`;

const Example = Styled.div`
  width: 34rem;
  text-align: center;
  margin: auto;
  min-width: 34rem;

  ${Media.greaterThan("medium")`
    width: 31.5%;
  `}
`;

const LinkExtended = Styled(Link)`
  &:hover, &:focus {
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
  }
`;

const Image = Styled.img`
  display: block;
  height: 20rem;
`;

const ImageContainer = Styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);

  &:after {
    content: 'Learn More';
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
    padding-top: 25%;
    align-content: center;
    font-size: 2rem;
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    background: rgba(0,0,0,0.45);
    opacity: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  &:hover:after {
    opacity: 1;
  }
`;

const ExampleTitle = Styled(Headline)`
  font-size: 2rem;

  ${Media.lessThan("medium")`
    font-size: 1.6rem;
  `}
`;

const FlexContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonExtended = Styled(Button)`
`;

const NavButtonLink = Styled.a`
`;

type TemplatesProps = {
  title: string;
  description: string;
  h1?: boolean;
  linkPage?: string;
  thumbnails: Array<{
    image: any;
    alt: string;
    title: string;
    link: string;
  }>;
};

const TemplatesSection = ({
  title,
  description,
  linkPage,
  thumbnails,
  h1,
}: TemplatesProps) => (
  <Content narrow>
    <Columns>
      <ColumnText>
        <FlexContainer>
          {h1 === true ? (
            <HeadlineExtended as="h1">{title}</HeadlineExtended>
          ) : (
            <HeadlineExtended>{title}</HeadlineExtended>
          )}
          {linkPage && (
            <NavButtonLink href={linkPage}>
              <ButtonExtended variant="primarylight" solid>
                Show all
              </ButtonExtended>
            </NavButtonLink>
          )}
        </FlexContainer>

        <RichDescription content={description} />
        
        {/*<TemplateThumbnailsPagination thumbnails={thumbnails}/>*/}

        <Examples>
          {thumbnails.map((example) => (
            <Example>
              <LinkExtended to={example.link}>
                <ImageContainer>
                  <Image src={example.image} alt={example.alt} />
                </ImageContainer>
                <ExampleTitle>{example.title}</ExampleTitle>
              </LinkExtended>
            </Example>
          ))}
          </Examples>
      </ColumnText>
    </Columns>
  </Content>
);

export default TemplatesSection;
