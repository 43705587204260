import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";

import Content from "../../../layout/Content/Content";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Input from "../../../form/Input";
import Button from "../../../buttons/Button";

const Container = Styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  padding: 3rem 0rem;
  border-radius: 2rem;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.gray.dark};
  
  ${Input} {
    border: 1px solid white;
  }

  ${Media.greaterThan("medium")`
    padding: 7rem 0;
  `}
`;

const Columns = Styled.div`
  ${Media.greaterThan("medium")`
    margin: 0 0 5rem 0;
  `}

  ${Media.greaterThan("large")`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 -1.5rem;
  `}
`;

const Column = Styled.div`
  ${Media.greaterThan("large")`
    width: calc(100% / 2);
    padding: 0 5rem 0 1.5rem;
  `}
`;

const HeadlineExtended = Styled(Headline)`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  margin: 0 0 1rem 0;

  ${Media.greaterThan("medium")`
      margin: 0 0 1rem 0;
  `}

  ${Media.greaterThan("large")`
    text-align: left;
  `}
`;

const DescriptionExtended = Styled(Description)`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  margin: 0 0 1.5rem 0;
  
  ${Media.greaterThan("medium")`
    margin: 0 0 3rem 0;
  `}
  
  ${Media.greaterThan("large")`
    text-align: left;
  `}
`;

const ImageA = Styled(Image).attrs({
  style: {
    position: "absolute",
  },
})`
  display: none;

  ${Media.between("medium", "large")`
    display: block;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100%;
    max-width: calc(100% - 6rem);
  `}
  

  ${Media.greaterThan("large")`
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 62rem;
    max-width: 50%;
  `}
 
`;

const ImageASpacer = Styled.div`
  ${Media.between("medium", "large")`
    width: 100%;
    height: 0;
    padding-bottom: calc(43% - 1.5rem);
  `}
`;

const SignupContainer = Styled.div`
  max-width: 43rem;
  margin: 0 auto;

  ${Media.greaterThan("large")`
    margin: 0;
  `}
`;

export const ButtonExtended = Styled(Button)`
  ${Media.lessThan("large")`
    display: block;
    margin: auto;
  `}
`;

const NavButtonLink = Styled.a`
  
`;

type ShoutoutProps = {
  headline: string;
  description: string;
  cta: string;
  imageA: GatsbyImageProps;
  link?: string;
};

export default ({
  headline,
  description,
  cta,
  imageA,
  link=`https://${constants.APP_DOMAIN}/auth/sign-up`
}: ShoutoutProps) => (
  <Container>
    <Content>
      <Columns>
        <Column>
          <HeadlineExtended>{headline}</HeadlineExtended>
          <DescriptionExtended>{description}</DescriptionExtended>

          <NavButtonLink href={link}>
            <ButtonExtended solid>{cta}</ButtonExtended>
          </NavButtonLink>
        </Column>

        <Column />
      </Columns>

      <ImageA
        fluid={"fluid" in imageA ? imageA.fluid : ""}
        alt="Screenshot of mobile app screens and web pages designed in Uizard"
      />
      <ImageASpacer />
    </Content>
  </Container>
);
