import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import TemplatesSection from "../../../components/pages/templates/TemplatesSection";
import Content from "../../../components/layout/Content/Content";
import Shoutout from "../../../components/pages/signup/Shoutout";

import eShopImage from "../../../components/pages/home/HomeExamples/e_shop_cover.png";
import landoImage from "../../../components/pages/home/HomeExamples/lando_cover.png";
import webWireframeImage from "../../../components/pages/home/HomeExamples/web_wireframe_cover.png";
import estateImage from "../../../components/pages/home/HomeExamples/estate_cover.png";
import webuikitImage from "../../../components/pages/home/HomeExamples/web-ui-kit-cover.png";
import blogwebsiteImage from "../../../components/pages/home/HomeExamples/food-blog-web-plp.png";
import embarkImage from "../../../components/pages/home/HomeExamples/embark_cover.png";
import web3Image from "../../../components/pages/home/HomeExamples/web3_crypto_website_cover.png";
import nftmarketImage from "../../../components/pages/home/HomeExamples/nft-market-website-plp.png";
import marketplaceImage from "../../../components/pages/home/HomeExamples/marketplace-website-plp.png";
import petShopImage from "../../../components/pages/home/HomeExamples/pet_web_store_cover.png";
import googleMUIImage from "../../../components/pages/home/HomeExamples/google-material-3-ui-kit-web-plp.jpeg";
const ContentExtended = Styled(Content)`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}
`;

const BreadcrumbContainer = Styled.div`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}

    margin-bottom: 1rem;
`;

const BreadcrumbLink = Styled(Link)`
  font-size: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }
`;

const Breadcrumb = Styled.span`
  font-size: 1.5rem;
`;

const Chevron = Styled.span`
  position: relative;
  margin: 0 0.5rem;
  top: 0.35rem;
`;

const Templates = ({ data }) => (
  <Layout>
    <Meta
      title="Website Design Templates | Website Templates | Uizard"
      description="Start any website project faster using Uizard's beautiful pre-made templates. Customize your design rapidly with your team."
      url="/templates/website-templates/"
    />

    <ContentExtended narrow>
      <BreadcrumbContainer>
        <BreadcrumbLink to="/templates/">Templates</BreadcrumbLink>
        <Chevron aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
          </svg>
        </Chevron>
        <Breadcrumb>Website Templates</Breadcrumb>
      </BreadcrumbContainer>
    </ContentExtended>

    <TemplatesSection
      title="Website design templates"
      description="Start any website project faster using Uizard's beautiful pre-made templates. With a stunning range of web design templates to choose from, there really is something for everyone. Whether you are looking for a <a:https://uizard.io/templates/website-templates/website-wireframe/>website wireframe</a> or a website UI mockup with a little sparkle, our range is sure to impress."
      h1={true}
      thumbnails={[
        {
          image: webWireframeImage,
          alt: "website wireframe template",
          title: "Website wireframe",
          link: "/templates/website-templates/website-wireframe/",
        },
        {
          image: blogwebsiteImage,
          alt: "blog website design template",
          title: "Blog website",
          link: "/templates/website-templates/blog-website/",
        },
        {
          image: embarkImage,
          alt: "business homepage design template",
          title: "Business website homepage",
          link: "/templates/website-templates/business-home-page/",
        },
        {
          image: eShopImage,
          alt: "e-shop website design template",
          title: "E-commerce website",
          link: "/templates/website-templates/e-commerce-website/",
        },
        {
          image: googleMUIImage,
          alt: "google mui design template",
          title: "Google MUI Web Kit",
          link: "/templates/website-templates/material-design-3-ui-kit/",
        },
        {
          image: marketplaceImage,
          alt: "marketplace website design template",
          title: "Marketplace website",
          link: "/templates/website-templates/marketplace-website/",
        },
        {
          image: nftmarketImage,
          alt: "nft marketplace website design template",
          title: "NFT marketplace website",
          link: "/templates/website-templates/nft-marketplace-website/",
        },
        {
          image: petShopImage,
          alt: "pet shop pwebsite design template",
          title: "Pet shop website",
          link: "/templates/website-templates/pet-store/",
        },
        {
          image: estateImage,
          alt: "real estate website design template",
          title: "Real estate website",
          link: "/templates/website-templates/real-estate-website/",
        },
        {
          image: landoImage,
          alt: "startup landing page design template",
          title: "Startup website landing page",
          link: "/templates/website-templates/startup-landing-page/",
        },
        {
          image: webuikitImage,
          alt: "universal web ui kit",
          title: "Universal web UI kit",
          link: "/templates/website-templates/universal-web-ui-kit/",
        },
        {
          image: web3Image,
          alt: "web3 crypto website design template",
          title: "Web3 crypto website",
          link: "/templates/website-templates/web3-crypto-website/",
        },
      ]}
    />

    <Content>
      <Shoutout
        headline="Design a website in minutes"
        description="No tutorial needed!"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default Templates;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
