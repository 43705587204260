import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

const Input = Styled.input`
  display: block;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 1.6rem;
  height: 4.4rem;
  line-height: 4.4rem;
  padding: 0 1.5rem;
  font-weight: normal;
  background: transparent;
  border: 0;
  border-top: 1px solid transparent;
  border: none;
  border-radius: 0.8rem;
  color: ${(props) => props.theme.colors.piner};
  outline: none;
  transition: .3s;
  
   ${(props) =>
     props.backgrounded &&
     `
    background-color: ${props.theme.colors.gray.mediumer};
    border-bottom: 0;
    border-radius: 1rem;
    padding: 0 1.7rem;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border: none;
  `}

  ${(props) =>
    props.material &&
    `
    border-left: 0;
    border-right: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid ${props.theme.colors.gray.light};
    padding: 0;
    border-radius: 0;
  `}

  &::placeholder {
    color: ${(props) => props.theme.colors.gray.piner};
  }

  &::selection {
    background-color: ${(props) => props.theme.colors.primary};
  }

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.black};

    ${(props) =>
      props.material &&
      `
      border-top-color: transparent;
    `}
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
  `}

  ${Media.greaterThan("medium")`
    height: 5rem;
    line-height: 5rem;
    font-size: 1.8rem;
  `}
`;

export default Input;
